<template>
  <div class="mb-3">
    <multiselect
      :id="'ms' + companyType"
      label="name"
      track-by="id"
      :placeholder="'Type to search available ' + companyType"
      open-direction="bottom"
      :options="getSuppliers.availableRecords"
      :value="getSelectedSuppliers"
      @input="setSelectedSuppliers"
      @search-change="search"
      @open="load('')"
      :multiple="true"
      :searchable="true"
      :loading="isSearching"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="false"   
      :disabled="getIsSpg"        
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          {{ customLabel(props) }}
        </div>
      </template>

      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="getSelectedSuppliers.length"
          @mousedown.prevent.stop="clearSelected(props.search)"
        ></div>
      </template>
      <span slot="noResult"
        >No {{ companyType }} found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const BillTosRepository = RepositoryFactory.get("billTosRepository");

export default {
  props: ["userId", "customerId", "selectedBillTos", "selectedConsignees"],
  components: {
    Multiselect
  },
  data() {
    return {
      companyType: "Suppliers",
      isSearching: false,
      isDirty: true
    };
  },
  async mounted() {
    //await this.load();
  },
  methods: {
    ...mapActions("companyMultiSelects", [
      "setSuppliers",
      "setSelectedSuppliers"
    ]),    
    limitText(count) {
      return `and ${count} other ${this.companyType}`;
    },
    customLabel(props) {
      return `${props.option.name} (Id: ${props.option.id})`;
    },
    search: _.debounce(async function(query) {
      this.isDirty = true;
      await this.load(query);
    }, 350),
    async load(query) {
      if (!this.isDirty) return;

      this.isSearching = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        customerId: this.customerId,
        userId: this.userId,
        division: this.getSelectedDivisions.map(a => a.source),
        billTo: this.getSelectedBillTos.map(a => a.id),
        consignee: this.getSelectedConsignees.map(a => a.id),
        shipper: this.getSelectedShippers.map(a => a.id),
        searchTerm: query,
        skip: 0,
        take: 20
      };

      BillTosRepository.getSuppliers(requestData)
        .then(response => {
          this.setSuppliers({
            availableRecords: response.data.results,
            totalRecords: response.data.totalRecords
          });

          this.isDirty = false;
        })
        .catch(error => {
           console.log(error);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
    clearSelected() {
      this.setSelectedSuppliers([]);
      //this.repopulateTheOthers();  move to state
    }
  },
  computed: {
    ...mapGetters("companyMultiSelects", [
      "getSuppliers",
      "getSelectedSuppliers",
      "getIsSpg",
      "getSelectedDivisions",
      "getSelectedBillTos",
      "getSelectedConsignees",
      "getSelectedShippers"
    ])
  },
  watch: {
    
    getSelectedShippers(newValue, oldValue) {
      this.isDirty = true;
      this.setSuppliers({
        availableRecords: [],
        totalRecords: 0
      });
    }
  }
};
</script>
